import { template as template_cf5ace7ab3754e57a3a0f1c2ece8398f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_cf5ace7ab3754e57a3a0f1c2ece8398f(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;

import { template as template_1cd5fb42507f40c688c9303749f3293d } from "@ember/template-compiler";
const FKControlMenuContainer = template_1cd5fb42507f40c688c9303749f3293d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

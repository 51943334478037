import { template as template_a95999ff87ef42f6bc98172b2dc29db7 } from "@ember/template-compiler";
import { concat, hash } from "@ember/helper";
import { LinkTo } from "@ember/routing";
import i18n from "discourse-common/helpers/i18n";
const GroupActivityFilter = template_a95999ff87ef42f6bc98172b2dc29db7(`
  <li>
    <LinkTo
      @route={{concat "group.activity." @filter}}
      @query={{hash category_id=@categoryId}}
    >
      {{i18n (concat "groups." @filter)}}
    </LinkTo>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupActivityFilter;

import { template as template_682c837a05b04bb9b66ecf6711fb340f } from "@ember/template-compiler";
const FKLabel = template_682c837a05b04bb9b66ecf6711fb340f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_f4418cdf2f874d05a5b4bb6151d20984 } from "@ember/template-compiler";
const FKText = template_f4418cdf2f874d05a5b4bb6151d20984(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
